<template>
  <b-container fluid>
        <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.periodic_activity_monitoring') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
                >
                <b-form-select
                    plain
                    v-model="search.thematic_area_id"
                    :options="thematicAreaList"
                    id="thematic_area_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.project_id')"
                label-for="project_status"
                >
                <b-form-input
                    plain
                    v-model="search.project_id"
                    id="project_status"
                    >
                </b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.periodic_activity_monitoring') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(statusCount)="data">
                        <span class="badge badge-danger" v-if="data.item.statusCount === 3">{{ currentLocale == 'bn' ? 'বিচারাধীন (অনুমোদন প্রয়োজন)' : 'Pending (Need Approval)' }}</span>
                        <span class="badge badge-success" v-else-if="data.item.statusCount === 2">{{ currentLocale == 'bn' ? 'সম্পন্ন' : 'completed' }}</span>
                        <span class="badge badge-warning" v-else>{{ currentLocale == 'bn' ? 'চলমান' : 'Ongoing' }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <router-link :to="`periodic-activity-monitoring-details?id=${data.item.id}`" :title="$t('n_c_data_bank.view')" :class="'btn btn-success text-light mr-2'"><i class="ri-eye-line m-0 "></i></router-link>
                        <!-- <router-link :to="`periodic-activity-monitoring-gantt-chart?id=${data.item.id}`" title="Gantt Chart" :class="'btn btn-success text-light'">Gantt Chart</router-link> -->
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { periodicActivityMonitoringList } from '../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [RouteBasedList],
  components: {
  },
  data () {
    return {
      baseUrl: agriResearchServiceBaseUrl,
      testId: 0,
      rows: [],
      itemId: 0,
      item: '',
      search: {
        thematic_area_id: 0,
        project_id: ''
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    thematicAreaList: function () {
        return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.update')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('research_manage.project_id'), class: 'text-left' },
          { label: this.$t('research_manage.project_title'), class: 'text-left' },
          { label: this.$t('external_research.thematic_area'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_title_bn' },
          { key: 'thematic_area_name_bn' },
          { key: 'statusCount' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_title' },
          { key: 'thematic_area_name' },
          { key: 'statusCount' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
    // flatpickr('.fromDate', {})
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    note (item) {
      this.itemId = item.id
    },
    loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(agriResearchServiceBaseUrl, periodicActivityMonitoringList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        let countSt = 0

        const newActivityList = item.activities.filter(activity => activity.activity_status === 2)
        if (newActivityList.length === item.activities.length) {
          countSt = 2
        } else {
            const activityStatus = item.activities.find(activity => activity.activity_status === 3)
            if (activityStatus !== undefined) {
              countSt = 3
            } else {
              countSt = 1
            }
        }

        const thematicAreaObj = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(Item => Item.value === item.invitation.thematic_area_id)
        const newData = {
          statusCount: countSt,
          thematic_area_name: thematicAreaObj === undefined ? null : thematicAreaObj.text_en,
          thematic_area_name_bn: thematicAreaObj === undefined ? null : thematicAreaObj.text_bn
        }

        return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
